import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const PGD = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Grupa PGD",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #1C085F, #2E1485, #3C1CA6, #2E1485, #1C085F )",
        icons: "#4a059b",
        navClass: "pgd",
        ogImage: require("../../assets/img/portfolio/pgd_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/grupa-pgd/",
      }}
    >
      <PortfolioHeader name="pgd" height="132" />
      <section className="container-fluid pgd_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Grupa PGD</h1>
              <ul>
                <li>Strona internetowa</li>
                <li>Praca konkursowa</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                Grupa PGD to pierwsza polska grupa dealerska; prekursor
                wielomarkowości w Polsce i pierwszy polski dealer, który
                utworzył (wielomarkowy) salon samochodowy za granicą. Grupa PGD
                to pierwszy dealer w Polsce, który przekroczył granicę 10
                tysięcy sprzedanych samochodów w roku i 200 tysięcy sprzedanych
                aut w historii. Od 2004 roku firma jest notowana na listach 500
                największych polskich przedsiębiorstw przygotowywanych przez
                redakcję Polityki oraz Rzeczpospolitej.
              </p>
              <p>Cel:</p>
              <ol>
                <li>Zaprojektować nowy serwis sprzedażowy </li>
                <li>
                  Przedstawić markę jako nowoczesne przedsiębiorstwo
                  <br /> odpowiadające pełniącej przez niego roli lidera rynku.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid pgd_section_3">
        <div className="row row_1">
          <div className="col-md-6 offset-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/pgd_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row row_2">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/pgd_main_2.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row row_3">
          <div className="col-md-8 offset-md-4 text-right">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/pgd_main_3.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid pgd_section_4">
        <div className="row">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/pgd_main_4.png")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="inner">
              <h3>Efekt:</h3>
              <p>
                Zaprojektowaliśmy nowoczesny i wyróżniający się na rynku serwis
                internetowy. Opracowaliśmy koncepcję, która podkreślała wiodącą,
                na polskim rynku, rolę Grupy PGD. Finalnie Klient nie zdecydował
                się na zmianę swojego serwisu internetowego.
              </p>
            </div>
          </div>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default PGD;
